 export const clientSideDoctors = [
  {
    name: "Noopur Singh (MBBS, MS)",
    department: "Consultant Obstetrics and Gynaecology",
    info: "Consultant Obstetrician and Gynecologist, specializes in infertility treatments, high-risk pregnancies, and complicated gynecological surgeries.",
    image:
      "https://res.cloudinary.com/dzlnfqbrz/image/upload/v1/Fah/dlxrsiz7s2moi92ari6p.jpg?_a=BAMADKRg0",
  },
  {
    name: "Alka Saini (MBBS, DGO)",
    department: "Consultant Obstetrics and Gynaecology",
    info: "Consultant Gynecological surgeries, including abdominal and vaginal hysterectomies, tubal recanalization, vaginoplasty, ovarian tumors.",
    image:
      "https://res.cloudinary.com/dzlnfqbrz/image/upload/v1/Fah/heeyhf0na4vk2kbx2qio.jpg?_a=BAMADKRg0",
  },
  {
    name: "Vikram singh (MBBS, M.S., MCh Urologist(AIIMS))",
    department: "Consultant Urologist",
    info: "Accomplished Urosurgeon and Andrologist is a distinguished Consultant in Surgery with an M.S. in Surgery and an M.Ch. in Urology from AIIMS.",
    image:
      "https://res.cloudinary.com/dzlnfqbrz/image/upload/v1/Fah/onanremlpyodfjeo5ko0.jpg?_a=BAMADKRg0",
  },
  {
    name: "Ankur Agarwal(MD (Medicine), DM (CARDIO))",
    department: "Consultant Interventional Cardiologist",
    info: "Specializes in coronary and peripheral angiographies and angioplasties with IVUS/OCT support, pacemaker implantations (single and dual chamber), ICD and CRT, 2D ECHO, device closures, and balloon valvotomies.",
    image:
      "https://res.cloudinary.com/dzlnfqbrz/image/upload/v1/Fah/ixx2bmiietb4nwzyytow.jpg?_a=BAMADKRg0",
  },
  {
    name: "T.H. Faruqui(MBBS,MD (Medicine))",
    department: "Consultant Interventional Cardiologist",
    info: "Highly qualified medical professional, holding dual degrees of MD in Medicine and MD in Forensic Medicine. As a consultant physician, offers a wealth of expertise and experience in managing chronic illnesses.",
    image:"https://res.cloudinary.com/dzlnfqbrz/image/upload/v1/Fah/inxv9nsroybv5anhhorv.jpg?_a=BAMADKRg0",
  },
  {
    name: "Anany Gupta(MD (Medicine),DM (Gastro))",
    department: "Consultant  Gastroenterologist",
    info: "Gold Medalist, Interventional Gastroenterologist, MD-Medicine, DM Gastroenterology (AIIMS NEW DELHI), FIP-Fellow in Pancreatology (AIIMS NEW DELHI), Formerly-Fellow of Advanced endoscopy (ESD)",
    image:"https://res.cloudinary.com/dzlnfqbrz/image/upload/v1/Fah/pz9rislaef27ivuhjfhj.jpg?_a=BAMADKRg0",
  },
];
